import React from "react"

import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/base"
import { Header } from "../components/Vostochniy/Header"
import { Banner } from "../components/Vostochniy/Banner"
import Advantages from "../components/Advantages"
import Discounts from "../components/Discounts"
import Conditions from "../components/Conditions"
import WaysToGetCard from "../components/WaysToGetCard"
import Partners from "../components/Partners"
import Footer from "../components/Footer"
import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { FormPKW } from "../components/FormPKW"

const title = "Банк Восточный – условия для клиентов по карте рассрочки Халва"
const description =
  "Узнайте больше об условиях пользования картой Халва от Совкомбанка для клиентов Банка Восточный."

export default function Page({ data }: PageProps<PageData>) {
  const {
    ligal,
    conditions: { items: conditionsItems, head: conditionsHead },
    phones,
    noIndex,
  } = getPageData(data)

  return (
    <Layout title={title} description={description} noIndex={noIndex}>
      <Header phones={phones} />
      <Banner orderNum="2" />
      <Advantages orderNum="3" />
      <Discounts orderNum="4" />
      <Conditions items={conditionsItems} head={conditionsHead} orderNum="5" />
      <WaysToGetCard orderNum="6" />
      <FormPKW
        longForm
        dataLayerName="shortPersonalForm"
        title="Оформить заявку"
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="7"
        subTitle="Заберите его в ближайшем офисе Совкомбанка"
        emptyForm={false}
      />
      <Partners orderNum="8" />
      <Footer shortText={ligal} phones={phones} />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/vostochniy/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
    }
    pageDataJson(pageName: { eq: "vostochniy" }) {
      conditions {
        items {
          img
          title
          desc
        }
        head
      }
    }
  }
`
