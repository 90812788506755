import React from "react"

import HeaderMain from "../../Headers/Main"

import logo from "./img/logo.svg"

import * as styles from "./header.module.scss"

export function Header({ phones }: { phones?: string[] }) {
  return (
    <HeaderMain phones={phones}>
      <div className={styles.logoOuter}>
        <img src={logo} alt="logo" />
      </div>
    </HeaderMain>
  )
}
