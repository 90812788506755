import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import ImgBg from "./ImgBg"

import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Заказать карту"

type BannerProps = {
  orderNum?: string
}

export function Banner({ orderNum }: BannerProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <ImgBg alt="bg image" className={styles.img} />
      <Container className={styles.container}>
        <div>
          <h1 className={styles.title}>Карта «Халва» для клиентов Банка «Восточный»</h1>
          <div className={styles.text}>Рассрочка на все 0%</div>
        </div>
        <Button
          className={styles.btn}
          onClick={() => handleClickBanner("click_cta")}
          color="secondary"
        >
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
